@use "@angular/material" as mat;
@use "./custom-theme" as *;
@use "app/projects/elements/smart-suggestion-modal/smart-replace-modal.component.global";
@use "assets/css/material-radio-button.scss";
@use "assets/css/material-checkbox.scss";

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100..900&family=Space+Grotesk:wght@300..700&display=swap");

$kwf-primary: (
  (
    0: #000000,
    10: #001b3f,
    20: #002f65,
    25: #003a7a,
    30: #00458f,
    35: #0050a5,
    40: #0B3D91,
    50: #0074e9,
    60: #438fff,
    70: #7cabff,
    80: #abc7ff,
    90: #d7e3ff,
    95: #ecf0ff,
    98: #f9f9ff,
    99: #fdfbff,
    100: #ffffff,
    secondary: (
      0: #000000,
      10: #3f51b5,
      20: #ad1457,
      25: #c2185b,
      30: #d81b60,
      35: #e91e63,
      40: #ec407a,
      50: #f06292,
      60: #f48fb1,
      70: #f8bbd0,
      80: #fce4ec,
      90: #0000001F,
      95: #ff94c2,
      98: #ffbdd3,
      99: #ffebee,
      100: #ffffff,
    ),
    neutral: (
      0: #000000,
      10: #000000DE,
      20: #2f3033,
      25: #3b3b3f,
      30: #46464a,
      35: #525256,
      40: #5e5e62,
      50: #77777a,
      60: #919094,
      70: #ababaf,
      80: #c7c6ca,
      90: #e3e2e6,
      95: #f2f0f4,
      98: #faf9fd,
      99: #fdfbff,
      100: #ffffff,
    ),
    neutral-variant: (
      0: #000000,
      10: #181c22,
      20: #2d3038,
      25: #383b43,
      30: #0B3D91,
      35: #4f525a,
      40: #5b5e66,
      50: #74777f,
      60: #8e9099,
      70: #a9abb4,
      80: #c4c6d0,
      90: #e0e2ec,
      95: #eff0fa,
      98: #f9f9ff,
      99: #fdfbff,
      100: #ffffff,
    ),
    error: (
      0: #000000,
      10: #b71c1c,
      20: #c62828,
      25: #d32f2f,
      30: #e53935,
      35: #f44336,
      40: #ef5350,
      50: #e57373,
      60: #ef9a9a,
      70: #ffcdd2,
      80: #ff8a80,
      90: #ff5252,
      95: #ff1744,
      98: #d50000,
      99: #ffebee,
      100: #ffffff,
    ),
  )
);

html,
body {
  :root {
    @include mat.sidenav-overrides((
      container-width: 230px,
    ));
  }
}
body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 450;
}
[hidden] {
  display: none !important;
}

.title-and-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.625rem;
}

.title-and-btn h1 {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.375rem;
  font-family: "Space Grotesk", serif;
}

.page-description p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.188rem;
  letter-spacing: 0.05em;
  color: #7d7d7d;
}

.goto-selection {
  background-color: #d7dce5;
  border: 1px solid #B0E0E6;
  border-radius: 10px;
}
.goto-selection:after {
  font-family: "Material Icons", serif;
  content: "\e89e";
}

app-create-user-modal {
  .mdc-text-field {
    padding: 0;
  }

  .mat-mdc-select {
    padding: 0.75rem;
  }
}

.smart-replace-tooltip {
  font-size: 0.7rem;
  .mdc-tooltip__surface {
    background-color: #0B3D91;
    color: white;
  }

}

:root {

  .required-warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9rem;
    color: $error;
  
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $error;
      color: $primary-light;
      font-size: 0.6rem;
      border-radius: 0.75rem;
      min-height: 0.75rem;
      min-width: 0.75rem;
      margin-right: 0.625rem;
    }
  }

  table {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
  }

  .mat-mdc-tab-body.mat-mdc-tab-body-active {
    border-radius: 1rem;
  }

  .mat-mdc-table {

    .mat-mdc-header-cell {
      text-align: left;
      text-transform: uppercase;
      color: #0b3d91;
      font-size: .75rem;
      background: #0b3d911a;
      font-family: "Raleway", sans-serif !important;
      font-weight: 700;
    }

    .mat-mdc-row:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.02);
      border: none;
    }

    td {
      border: none;
      font-family: "Raleway", sans-serif !important;
      font-size: 16px;
    }
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: #0b3d91;
  }
  
  .mat-mdc-tab.mdc-tab--active .mdc-tab-indicator__content--underline {
    border-color: #0b3d91;
  }
  
  .mat-mdc-tab:hover .mdc-tab-indicator__content--underline {
    border-color: #0b3d91;
  }
  
  .mat-mdc-tab:focus .mdc-tab-indicator__content--underline {
    border-color: #0b3d91;
  }

  .mdc-tab--active .mdc-tab__text-label {
    color: #0b3d91;
  }
}
