@use "src/custom-theme" as *;

// form field

.mat-form-field {
  width: 100%;
}

.mat-input-element,
.mat-form-field-label,
.mat-checkbox-label {
  font-family: "Raleway", sans-serif !important;
}

.mat-form-field-label {
  color: #000;

  mat-icon {
    margin-right: 0.625rem;
  }
}

.mat-form-field-suffix .mat-icon-button {
  width: 2em !important;
  height: 2em !important;

  span {
    mat-icon {
      height: 1em !important;
      line-height: 1 !important;
      font-size: 1em !important;
      color: $primary-dark-blue;
    }
  }
}

.mat-focused .mat-form-field-label {
  color: #000 !important;
}

.mat-focused .mat-form-field-underline {
  background-color: #000 !important;
}

.mat-focused .mat-form-field-ripple {
  background-color: #000 !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: $error !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-underline {
  background-color: $error !important;
}

.mat-form-field-invalid .mat-form-field-label {
  color: $error !important;
}

.mat-form-field-invalid .mat-icon-button {
  span {
    mat-icon {
      color: $error !important;
    }
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
  border: 1px solid rgba(3, 3, 3, 0.3) !important;
  border-radius: 5px !important;
}

.mat-select-min-line,
.mat-option-text {
  font-family: "Raleway", sans-serif !important;
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-color: $primary-dark-gray !important;
  background-image: none !important;
}

.mat-form-field-disabled {
  opacity: 0.5;
  input {
    color: $primary-dark-gray !important;
  }
}

// Tabs

.mat-tab-group {
  font-family: "Raleway", sans-serif !important;
}

// toast

.custom-toast {
  margin-right: 5rem !important;
  margin-top: 5rem !important;
  .mdc-snackbar__surface {
    background-color: $primary-dark-blue !important;
    border-radius: 0.5rem !important;
  }
  .mat-mdc-snack-bar-label {
    padding: 0 !important;
  }
}

.custom-toast-error {
  margin-right: 5rem !important;
  margin-top: 5rem !important;
  .mdc-snackbar__surface {
    background-color: $error !important;
    border-radius: 0.5rem !important;
    min-width: auto !important;
  }
  .mat-mdc-snack-bar-label {
    padding: 0 !important;
  }
}

// Checkbox

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background {
  border-color: $primary-dark-blue !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  border-color: $primary-dark-blue !important;
  background-color: $primary-dark-blue !important;
}

// Dialog

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 1.25rem !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: unset !important;
}

// Text Area

.text-area-container .mat-form-field-underline {
  display: none !important;
}

.mdc-linear-progress__bar-inner {
  border-color: $primary-dark-blue !important;
}
