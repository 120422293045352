$primary-dark: #030303;
$primary-dark-gray: #7d7d7d;
$primary-light-gray: #eeeeee;
$primary-light: #fff;
$primary-dark-blue: rgb(11, 61, 145);
$primary-light-blue: #87ceeb;
$primary-dark-blue-10: #0b3d911a;
$primary-dark-blue-20: #0b3d9133;
$primary-dark-blue-30: #0b3d914d;
$primary-dark-blue-60: #0b3d9199;
$primary-dark-blue-70: #0b3d91b3;

$secondary-blue: #b0e0e6;
$secondary-light-blue: #dce1ea;
$secondary-light: #f3f3ea;
$secondary-lighter: #fffff0;
$secondary-dark-gray: #757575;

$error: #dc1919;
$error-70: #dc1919b3;
$error-15: #dc191926;
$warn: #f5cf08;
$success: #399600;

$error-20: #dc191933;

$highestRiskRatingColor: #d64545;
$highRiskRatingColor: #d64545b2;
$mediumRiskRatingColor: #f5cf08;
$lowRiskRatingColor: #61ab33;
$lowestRiskRatingColor: #399600;
