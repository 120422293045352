@use "src/custom-theme" as *;
@use "@angular/material" as mat;

.delete-btn {
  mat-icon {
    color: $error;
  }
}
.error-warning-directions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  margin-top: -18px;
  font-size: 0.9rem;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $error;
    color: $primary-light;
    font-size: 0.6rem;
    border-radius: 0.75rem;
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.625rem;
  }
}

h1 {
  margin: 0;
  padding: 0;
}

h2 {
  margin: 0;
  padding: 0;
}

.mat-mdc-form-field .mdc-text-field {
  padding: 2px;
}

.search-bar-size {
  width: 100%;
}

.mat-mdc-select-panel {
  background: white;
}
:root {
 @include mat.dialog-overrides((
  container-max-width: none
 )) 
}
:root {
  .mat-mdc-form-field-error-wrapper {
    padding-left: 0rem;
  }
  --mat-select-panel-background-color: #ffffff; /* Replace with your desired color */
}
:root {
  @include mat.form-field-overrides((
    container-height: 2.8rem,
    outlined-outline-width: 1px,
    outlined-outline-color: #0000001f,
    outlined-hover-outline-color: #0000001f,
    outlined-focus-outline-color: $primary-dark-blue,
    filled-with-label-container-padding-bottom: 0,
    filled-container-color: transparent,
    filled-disabled-container-color: transparent,
    outlined-container-shape: 5px,
    filled-hover-active-indicator-color: black,
    hover-state-layer-opacity: 0,
    filled-focus-active-indicator-color: gray,
    filled-active-indicator-color: gray,
    error-text-color: $error,
    outlined-label-text-color: black,
    filled-label-text-color: black,
  ));
}
