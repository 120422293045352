@use "../../custom-theme";

mat-checkbox.mat-mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: custom-theme.$primary-dark-blue;
  --mdc-checkbox-selected-hover-icon-color: custom-theme.$primary-dark-blue;
  --mdc-checkbox-selected-icon-color: custom-theme.$primary-dark-blue;
  --mdc-checkbox-selected-pressed-icon-color: custom-theme.$primary-dark-blue;
  --mdc-checkbox-unselected-icon-color: custom-theme.$primary-dark-blue;
  --mdc-checkbox-unselected-hover-icon-color: custom-theme.$primary-dark-blue;
  --mdc-checkbox-unselected-focus-icon-color: custom-theme.$primary-dark-blue;
  --mdc-checkbox-unselected-pressed-icon-color: custom-theme.$primary-dark-blue;

  .mdc-form-field {
    align-items: center;
  }

  .mdc-checkbox {
    .mdc-checkbox__background {
      border-color: custom-theme.$primary-dark-blue !important;
      background-color: transparent !important;
      transition: background-color 0.2s, border-color 0.2s;
    }

    .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background {
      border-color: custom-theme.$primary-dark-blue !important;
      background-color: custom-theme.$primary-dark-blue !important;
    }

    .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background {
      border-color: #bababa !important;
      //background-color: white !important;
    }

    .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
      border-color: custom-theme.$primary-dark-blue !important;
      background-color: custom-theme.$primary-dark-blue !important;
    }

    &:hover .mdc-checkbox__background {
      background-color: rgba(custom-theme.$primary-dark-blue, 0.04) !important;
    }

    .mdc-checkbox__ripple {
      background-color: transparent !important;
    }
  }

  .mat-mdc-checkbox-ripple {
    display: none !important;
  }
}
