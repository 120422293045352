@use "../../custom-theme";

mat-radio-button.mat-mdc-radio-button {
  .mdc-radio {
    padding-right: 8px !important;
    padding-left: 8px !important;

    .mdc-radio__outer-circle {
      opacity: 1 !important;
      border-color: custom-theme.$primary-dark-blue !important;
    }
  }
  .mdc-label {
    padding-left: 0 !important;
    color: #000000;
    //font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  &.mat-mdc-radio-checked {
    .mdc-radio__outer-circle {
      border-color: custom-theme.$primary-dark-blue;
      background-color: transparent !important;
    }
    .mdc-radio__inner-circle {
      border-color: custom-theme.$primary-dark-blue !important;
      background-color: white !important;
    }
  }
  .mdc-radio--disabled {
    .mdc-radio__outer-circle {
      opacity: 1 !important;
      border-color: #8e8b8b !important;
    }
    .mdc-radio__inner-circle {
      opacity: 1 !important;
      border-color: #a3a3a3 !important;
      background-color: #a3a3a3 !important;
    }
  }
}
