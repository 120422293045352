// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//         DO NOT WRITE CSS OUTSIDE app-smart-replace-modal
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
app-smart-replace-modal{
  div.mat-mdc-dialog-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 80%;
  }

  .smart-replace-options{

    .mdc-tab-indicator{
      display: none;
    }
    mat-tab-header.mat-mdc-tab-header{
      box-shadow: none;
      div.mdc-tab{
        padding: 0;
        margin-left: 0.75rem;
        &.mat-mdc-tab{
          flex-grow: 0;
        }

        &:first-child{
          margin-left: 0;
        }
        &.mdc-tab--active{
          .option-label{
            background-color: #B0E0E6;
          }
        }
      }
    }
  }
}
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//         DO NOT WRITE CSS OUTSIDE app-smart-replace-modal
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
