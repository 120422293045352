@use "sass:meta";
@use "@angular/material" as mat;
@use "./custom-theme" as *;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

$kwf-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$kwf-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$kwf-warn: mat.m2-define-palette(mat.$m2-red-palette);

//$kwf-theme: mat.m2-define-light-theme(
//  (
//    color: (
//      primary: $kwf-primary,
//      accent: $kwf-accent,
//      warn: $kwf-warn,
//    ),
//  )
//);

//@include mat.all-component-themes($kwf-theme);

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@include meta.load-css("assets/css/material-radio-button");

html,
body {
  @include mat.theme((
    color: (
      primary: mat.$azure-palette,
      tertiary: mat.$azure-palette,
      theme-type: light,
    ),
    typography: Roboto,
    density: 0
  ));
  height: 100%;
}
body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  color: #000000;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

[hidden] {
  display: none !important;
}

.title-and-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.625rem;
}

.title-and-btn h1 {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.375rem;
  font-family: "Space Grotesk";
}

.page-description p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.188rem;
  letter-spacing: 0.05em;
  color: #7d7d7d;
}

.required-warning {
  display: flex;
  color: $error;
  font-family: "Raleway", sans-serif;
  font-size: 0.75rem;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $error;
    color: $primary-light;
    font-size: 0.625rem;
    border-radius: 0.75rem;
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.625rem;
  }
}
.goto-selection {
  background-color: #d7dce5;
  border: 1px solid #B0E0E6;
  border-radius: 10px;
}
.goto-selection:after {
  font-family: "Material Icons";
  content: "\e89e";
}

app-create-user-modal{
  .mdc-text-field{
    padding: 0
  }

  .mat-mdc-select{
    padding: 0.75rem
  }

}
.mat-mdc-option{
  background-color: #fff
}

.mat-select-panel.dropdown {
  margin-top: 1.6rem !important;
}

@include meta.load-css("app/projects/elements/smart-suggestion-modal/smart-replace-modal.component.global");
.smart-replace-tooltip{
  font-size: 0.7rem;
  .mdc-tooltip__surface{
    background-color: #0B3D91;
    color: white;
  }

}


:root {
  @include mat.sidenav-overrides((
    //container-background-color: orange,
    //container-text-color: red,
    container-width: 230px,
  ));

  .mat-mdc-table {
    border-radius: 12px;
    margin-top: 15px;

    .mat-mdc-header-cell {
      text-align: left;
      text-transform: uppercase;
      color: #0b3d91;
      font-size: .75rem;
      background: #0b3d911a;
    }

    .mat-mdc-header-cell:first-child {
      border-radius: 20px 0 0 0;
    }

    .mat-mdc-header-cell:last-child {
      border-radius: 0 20px 0 0;
    }

    .mat-mdc-row:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.02);
      border: none;
    }

    td {
      border: none;
    }
  }
  .mat-mdc-dialog-component-host{
    .header{
      background-color: rgb(from var(--color-secondary) r g b / 20%);
    }
  }
}
